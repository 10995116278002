
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.video {
    :global {
        position: relative;
        width: 100%;
        height: 100%;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .lowcode-video-h5 {
            display: none;
        }
        .lowcode-video-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            width: 24px;
            height: 24px;
            margin: -12px 0 0 -12px;
            cursor: pointer;
        }
        @include devices(pad) {
            .lowcode-video-h5 {
                display: block;
            }
            .lowcode-video-pc {
                display: none;
            }
        }
    }
}
