
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        height: 600px;
        .swiper-box {
            height: 100%;
            &.auto-play {
                .swiper-pagination {
                    .swiper-pagination-bullet-active {
                        overflow: hidden;
                        &::after {
                            // &:local{
                            //     animation: progress 4s linear;
                            // }
                            display: none;
                        }
                        .after {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            background-color: #fff;
                            transform: translateX(-100%);
                        }
                    }
                }
            }
        }
        .swiper-item {
            position: relative;
            .banner-ms-box {
                .banner-btns {
                    .banner-link {
                        &:hover,
                        &:active,
                        &:focus {
                            opacity: 0.85;
                        }
                    }
                }
            }
        }
        @include devices(desktop-max) {
            height: 800px;
        }
        @include devices(pad) {
            min-height: 560px;
            height: auto;
            .swiper .swiper-pagination {
                .swiper-pagination-bullet {
                    width: 7px;
                    height: 7px;
                    margin: 0 7px;
                }
            }
        }
        @import 'lowcode/common/css/swiper-navigation-index.scss';
        @import 'lowcode/common/css/swiper-pagination-index.scss';
    }

    @keyframes progress {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(0);
        }
    }
}

.entry {
    display: none;
    :global {
        @include devices(pad) {
            & {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px;
                background-color: #fff;
            }
            .entry-text {
                .text {
                    color: #000;
                    font-size: 12px;
                    font-family: var(--ff-woff2-M);
                    margin-bottom: 8px;
                }
                .tip {
                    color: #707070;
                    font-family: var(--ff-woff2-M);
                    font-size: 12px;
                    .text-link {
                        color: #000;
                        font-size: 12px;
                        text-decoration-line: underline;
                    }
                }
            }
            .button-register {
                min-width: 90px;
                height: 34px;
                padding: 10px;
                border-radius: 2px;
                background-color: #000;
                color: #fff;
                font-family: var(--ff-woff2-EM);
                font-size: 12px;
                text-align: center;
                html[site='es'] &,
                html[site='de'] &,
                html[site='fr'] & {
                    display: flex;
                    align-items: center;
                    height: 42px;
                }
                html[site='fr'] & {
                    height: 48px;
                }
            }
        }
    }
}
