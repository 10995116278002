
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';


.content {
    :global {
        display: block;
        position: relative;
        margin: 0 auto 12px;
        padding: 0 20px;
        max-width: 1240px;

        ul {
            margin: 20px auto 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            li {
                width: 25%;
                text-align: center;
            }
        }

        .ShopifyGoodsA-imgbox {
            width: 100px;
            height: 100px;
            margin: 0 auto;
        }

        
    }
}
