
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.icon {
    :global {
        position: relative;
        display: flex;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
}
