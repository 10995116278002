
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.editor-text-title {
    display: block;
    position: relative;
    max-width: 1440px;
    padding: 0 120px;
    margin: 0 auto;
    line-height: 1;
    text-align: center;
    @include devices(pad) {
        padding: 0 48px;
    }
    @include devices(h5) {
        padding: 0 24px;
    }
}

.editor-text-title.position-left {
    text-align: left;
}
.editor-text-title.position-right {
    text-align: right;
}
