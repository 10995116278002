.swiper-pagination {
    position: absolute;
    bottom: 24px;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    .swiper-pagination-bullet {
        position: relative;
        width: 8px;
        height: 8px;
        margin: 0 6px;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        background-color: transparent;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0.4;
            border-radius: 8px;
        }
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 48px;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-color: #fff;
        }
    }
}