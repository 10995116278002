
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        margin: 0 auto 0;
        width: calc(100vw - 256px);
        cursor: pointer;
        
        .ListYoutube-title {
            font-family: var(--ff-woff2-EB);
            font-size: 28px;
            line-height: 1.1;
            text-align: center;
            margin-bottom: 40px;
        }
        .ListYoutube-in {
            display: flex;
            justify-content: center;
            .ListYoutube-item {
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .ListYoutube-item {
            position: relative;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            justify-content: center;
            flex-shrink: 0;
            height: 344px;
            overflow: hidden;
            border-radius: 14px;
            flex: 1;
            margin: 0 10px;
            text-align: center;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &.ListYoutube-active {
                .ListYoutube-item-in {
                    display: none !important;
                }
            }
        }
        .ListYoutube-item-in {
            position: relative;
            z-index: 12;
            width: 100%;
            height: 100%;
            text-align: left;
            &.ListYoutube-pos-center {
                text-align: center;
            }
            &.ListYoutube-pos-right {
                text-align: right;
            }
            .lowcode-img {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                border-radius: 8px;
                overflow: hidden;
            }
            .ListYoutube-msgs {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 0 24px 24px;
                color: #fff;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 54.19%);
            }
            .ListYoutube-item-title {
                font-family: var(--ff-woff2-B);
                font-size: 18px;
                line-height: 1.2;
            }
            .ListYoutube-item-desc {
                overflow:hidden;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-line-clamp:2;
                -webkit-box-orient:vertical;
                white-space: normal;

                font-family: var(--ff-woff2-M);
                line-height: 1.2;
                font-size: 14px;
                margin-top: 10px;
            }
            .ListYoutube-btn {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 56px;
                height: 56px;
                display: flex;
                transform: translate(-50%, -50%);
                cursor: pointer;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .ListYoutube-iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
            border: none;
            outline: none;
        }
        .ListYoutube-foot {
            display: flex;
            justify-content: center;
            font-family: var(--ff-woff2-M);
            text-align: center;
            font-size: 16px;
            color: #3F68E0;
            margin-top: 30px;
            .editor-link {
                display: flex;
                align-items: center;
                svg {
                    margin-left: 4px;
                }
            }
        }

        .ListYoutube-more{
            display: inline-block;
            margin: 30px auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            font-family: var(--ff-woff2-M);
            font-size: 14px;
            line-height: 1.2;
            color: #3F68E0;
            &:hover, &:active, &:focus{
                text-decoration: underline;
            }
            .more-icon{
                width: 13px;
                height: 13px;
                svg{
                    width: 100%;
                    height: 100%;
                     vertical-align: top;
                }
            }
        }

        @include devices(desktop-max){
            width: 1200px;
            .ListYoutube-title{
                font-size: 34px;
                margin-bottom: 50px;
            }
            .ListYoutube-in{
                .ListYoutube-item {
                    margin: 0 12px;
                    border-radius: 16px;
                    height: 344px;
                    .ListYoutube-msgs {
                        .ListYoutube-item-title{
                            font-size: 20px;
                        }
                    }
                    .ListYoutube-btn{
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }

        @include devices(pad) {
            width: 100%;
            .ListYoutube-title{
                font-size: 22px;
                margin-bottom: 30px;
            }
            .ListYoutube-in {
                padding: 0 48px;
                justify-content: flex-start;
                overflow-x: auto;
                flex-wrap: nowrap;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            .ListYoutube-item {
                flex: none;
                width: 320px;
                height: 300px;
                border-radius: 8px;
                margin: 0 8px;
                .ListYoutube-item-desc {
                    margin-top: 5px;
                }
                .ListYoutube-msgs{
                    .ListYoutube-item-title{
                        font-size: 20px;
                    }
                }
                .ListYoutube-btn{
                    width: 48px;
                    height: 48px;
                }
            }
        }

        @include devices(h5){
            .ListYoutube-in {
                padding: 0 24px;
                .ListYoutube-item {
                    margin: 0 6px;
                }
            }
        }
    }
}