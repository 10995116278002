
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        .ListNewsCard-title {
            width: calc(100vw - 628px);
            margin: 0 auto 40px;
            padding: 0 24px;
            font-family: var(--ff-woff2-EB);
            font-size: 28px;
            line-height: 1.1;
            text-align: center;
        }
        .ListNewsCard-list {
            display: flex;
            justify-content: center;
            width: calc(100vw - 256px);
            gap: 20px;
            margin: 0 auto;
            text-align: left;
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .ListNewsCard-item {
            width: 263px;
            border-radius: 14px;
            cursor: pointer;
            overflow: hidden;
        }
        .ListNewsCard-item-in {
            display: block;
            width: 100%;
            height: 100%;
            background-color: #F6F7F9;
        }
        .ListNewsCard-msgs {
            padding: 18px;
        }
        .ListNewsCard-img {
            height: 150px;
        }
        .ListNewsCard-item-title {
            font-family: var(--ff-woff2-B);
            font-size: 12px;
            line-height: 1.3;
            height: 32px;
            text-overflow: ellipsis;
            // white-space: nowrap;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #F8200C;
            white-space: pre-line;
        }
        .ListNewsCard-item-desc {
            height: 66px;
            line-height: 1.2;
            font-size: 18px;
            font-family: var(--ff-woff2-B);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            margin-top: 10px;
        }
        .ListNewsCard-item-footer{
            margin-top: 44px;
            display: flex;
            gap: 8px;
            .ListNewsCard-item-logo{
                width: 22px;
                height: 22px;
                margin-top: -4px;
            }
            .ListNewsCard-item-date {
                font-family: var(--ff-woff2-B);
                font-size: 14px;
                line-height: 1.2;
                color: #777;
            }
        }
        .ListNewsCard-btn {
            margin: 30px auto 0;
            width: max-content;
            display: flex;
            align-items: center;
            gap: 2px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.2;
            color: #3F68E0;
            &:hover, &:active, &:focus{
                text-decoration: underline;
            }
            svg {
                width: 13px;
                height: 13px;
            }
        }
        @include devices(desktop-max) {
            .ListNewsCard-title{
                margin-bottom: 50px;
                font-size: 34px;
                width: 792px;
            }
            .ListNewsCard-list{
                gap: 24px;
                width: 1200px;
            }
            .ListNewsCard-item {
                width: 282px;
                border-radius: 16px;
                .ListNewsCard-item-in{
                    .ListNewsCard-img{
                        height: 161px;
                    }
                    .ListNewsCard-msgs{
                        padding: 20px;
                        .ListNewsCard-item-desc{
                            font-size: 20px;
                            height: 72px;
                        }
                        .ListNewsCard-item-footer{
                            margin-top: 50px;
                            gap: 8px;
                            .ListNewsCard-item-logo{
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
            .ListNewsCard-btn {
                font-size: 16px;
                svg {
                    width: 13px;
                    height: 13px;
                }
            }
        }
        @include devices(pad) {
            width: 100%;
            .ListNewsCard-title{
                margin-bottom: 30px;
                font-size: 22px;
                width: calc(100vw - 268px);
            }
            .ListNewsCard-list{
                gap: 16px;
                width: 100%;
                justify-content: flex-start;
                padding: 0 48px;
                flex: 1 1;
                overflow-x: auto;
                flex-wrap: nowrap;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            .ListNewsCard-item {
                flex: none;
                width: 282px;
                border-radius: 10px;
                .ListNewsCard-item-in{
                    .ListNewsCard-img{
                        height: 161px;
                    }
                    .ListNewsCard-msgs{
                        padding: 20px;
                        .ListNewsCard-item-desc{
                            font-size: 16px;
                            height: 57px;
                        }
                        .ListNewsCard-item-footer{
                            margin-top: 50px;
                            gap: 8px;
                            .ListNewsCard-item-logo{
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
        @include devices(h5) {
            .ListNewsCard-title{
                width: calc(100vw - 48px);
            }
            .ListNewsCard-list {
                padding: 0 24px;
            }
        }
    }
}