
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.bannercard {
    :global {
        position: relative;
        overflow: hidden;
        width: 100vw;
        height: 600px;

        .banner-hot {
            font-family: var(--ff-woff2-EB);
            font-size: 16px;
            line-height: 1.2;
            margin-bottom: 6px;
        }

        .banner-title {
            font-family: var(--ff-woff2-EB);
            font-size: 34px;
            line-height: 1.1;
        }

        .banner-desc {
            margin-top: 16px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.3;
        }

        .banner-btns {
            margin-top: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 24px;

            .banner-link {
                min-width: 136px;
                padding: 10px 22px;
                text-align: center;
                border-radius: 4px;
                font-family: var(--ff-woff2-M);
                font-size: 16px;
                line-height: 1.2;
                box-sizing: border-box;

                &:hover,
                &.active,
                &:focus {
                    opacity: 0.85;
                }

                &.link-buy {
                    background-color: #fff;
                    color: #000;
                    border: 1px solid #fff;
                }

                &.link-more {
                    border: 1px solid #fff;
                    color: #fff;

                    &:hover,
                    &.active,
                    &:focus {
                        opacity: 0.6;
                    }
                }
            }
        }

        .banner-info {
            position: absolute;
            z-index: 10;
            width: 100%;
            top: 70px;
            text-align: center;
            white-space: pre-line;
            padding: 0 128px;

            html[site='sa'] & {
                direction: rtl;
            }
        }

        .banner-info-in {
            max-width: 922px;
            margin: 0 auto;
        }

        .banner-img {
            position: relative;
            width: 100%;
            height: 100%;
        }

        @include devices(desktop-max) {
            height: 800px;

            .banner-info {
                top: 90px;

                .banner-hot {
                    font-size: 20px;
                    line-height: 1.3;
                }

                .banner-title {
                    font-size: 48px;
                }

                .banner-desc {
                    font-size: 20px;
                }

                .banner-btns {
                    margin-top: 34px;
                    gap: 32px;

                    .banner-link {
                        font-size: 18px;
                    }
                }
            }
            .banner-info-in {
                max-width: 996px;
            }
        }

        @include devices(pad) {
            height: 580px;

            .banner-info {
                top: 50px;
                padding: 0 128px;

                .banner-hot {
                    font-size: 14px;
                    margin-bottom: 8px;
                }

                .banner-title {
                    font-size: 28px;
                }

                .banner-desc {
                    margin-top: 12px;
                    font-size: 14px;
                    line-height: 1.2;
                }

                .banner-btns {
                    margin-top: 26px;

                    .banner-link {
                        min-width: 124px;
                        padding: 10px 16px;
                    }
                }
            }
        }

        @include devices(h5) {
            .banner-info {
                padding: 0 24px;
            }
        }
    }

    &:global(.theme-black) {
        :global {
            .banner-info {
                .banner-title,
                .banner-desc {
                    color: #fff;
                }

                .banner-btns {
                    .banner-link {
                        &.link-buy {
                            background-color: #000;
                            color: #fff;
                        }

                        &.link-more {
                            border-color: #000;
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    &:global(.textAlign-left) {
        :global {
            .banner-info {
                text-align: left;
            }
            .banner-btns {
                justify-content: flex-start;
                html[site='sa'] & {
                    justify-content: flex-end;
                }
            }
        }
    }

    &:global(.textAlign-right) {
        :global {
            .banner-info {
                text-align: right;
            }
            .banner-btns {
                justify-content: flex-end;
                html[site='sa'] & {
                    justify-content: flex-start;
                }
            }
        }
    }

    &:global(.textAlign-h5-left) {
        :global {
            @include devices(pad) {
                .banner-info {
                    text-align: left;

                    .banner-btns {
                        justify-content: flex-start;
                        html[site='sa'] & {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }

    &:global(.textAlign-h5-center) {
        :global {
            @include devices(pad) {
                .banner-info {
                    text-align: center;

                    .banner-btns {
                        justify-content: center;
                        html[site='sa'] & {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    &:global(.textAlign-h5-right) {
        :global {
            @include devices(pad) {
                .banner-info {
                    text-align: right;

                    .banner-btns {
                        justify-content: flex-end;
                        html[site='sa'] & {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }

    &:global(.position-left) {
        :global {
            .banner-info {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: calc(1200px + 256px);
            }
            .banner-info-in {
                margin-left: 0;
                max-width: 50%;
            }
            @include devices(pad) {
                .banner-info {
                    padding: 0 48px;
                }
                .banner-info-in {
                    width: 100%;
                }
            }
            @include devices(pad) {
                .banner-info {
                    padding: 0 24px;
                }
            }
        }
    }
    &:global(.position-right) {
        :global {
            .banner-info {
                top: 50%;
                transform: translateY(-50%);
            }
            .banner-info-in {
                margin-right: 0;
                max-width: 50%;
            }
        }
    }
    &:global(.position-h5-left) {
        :global {
            @include devices(pad) {
                .banner-info {
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    padding: 0 48px;
                    width: 100%;
                }
                .banner-info-in {
                    margin-left: 0;
                    max-width: 100%;
                }
                @include devices(h5) {
                    .banner-info {
                        padding: 0 24px;
                    }
                }
            }
        }
    }
    &:global(.position-h5-center) {
        :global {
            @include devices(pad) {
                .banner-info {
                    top: 50px;
                    transform: none;
                }
                .banner-info-in {
                    margin-right: 0;
                    max-width: 100%;
                }
            }
        }
    }
    &:global(.position-h5-right) {
        :global {
            @include devices(pad) {
                .banner-info {
                    top: 50%;
                    transform: translateY(-50%);
                }
                .banner-info-in {
                    margin-right: 0;
                    max-width: 100%;
                }
            }
        }
    }
}
