
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.comp {
    :global {
        display: block;
        .activity-card-title {
            position: relative;
            font-family: var(--ff-woff2-B);
            margin: 0 auto 24px;
            max-width: 888px;
            padding: 0 48px;
            font-size: 40px;
            text-align: center;
            color: #000;
            white-space: pre-line;
        }
        .activity-card-desc {
            max-width: 888px;
            padding: 0 48px;
            font-family: var(--ff-woff2-M);
            font-size: 20px;
            line-height: 1.2;
            text-align: center;
            color: #707070;
            white-space: pre-line;
            margin: 0 auto 40px;
        }
        .activity-card-in {
            max-width: 1296px;
            padding: 0 48px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
        }
        .activity-card-in.activity-card-column2 {
            grid-template-columns: 1fr 1fr;
        }
        .activity-card-in.activity-card-column3 {
            grid-template-columns: 1fr 1fr 1fr;
        }
        .activity-card-in.activity-card-column4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .activity-card-in.activity-card-addCart3 {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include devices(pad) {
            .activity-card-in.activity-card-column3 {
                grid-template-columns: 1fr 1fr;
                gap: 12px;
            }
            .activity-card-in.activity-card-column4 {
                grid-template-columns: 1fr 1fr;
                gap: 12px;
            }
            .activity-card-in.activity-card-addCart3 {
                grid-template-columns: 1fr 1fr;
                gap: 12px;
            }
        }
        @include devices(pad) {
            .activity-card-title {
                font-size: 20px;
                margin-bottom: 16px;
            }
            .activity-card-desc {
                font-size: 14px;
            }
        }
        @include devices(h5) {
            .activity-card-title, .activity-card-desc {
                padding: 0 12px;
            }
            // 左右间隔都调成12px
            .activity-card-in {
                padding: 0 12px;
                gap: 12px;
            }
            .ef-activity-card-a-text {
                padding-left: 12px;
                padding-right: 12px;
            }
            .ef-activity-card-a-footer {
                padding-left: 12px;
                padding-right: 12px;
            }
            .ef-activity-card-a-discount {
                margin-left: 12px;
            }
        }
    }
}
