
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        margin: 0 auto;
        .SwiperCardB-title {
            width: calc(100vw - 632px);
            margin: 0 auto 40px;
            text-align: center;
            font-family: var(--ff-woff2-EB);
            font-size: 28px;
            line-height: 1.1;
            color: #000;
        }
        .SwiperCardB-in.SwiperCardB-show-lr {
            display: flex;
            width: 100%;
            .SwiperCardB-in-l, .SwiperCardB-in-r {
                position: relative;
                flex: 1;
                height: 298px;
                min-width: 48px;
                cursor: pointer;
            }
            .SwiperCardB-icon {
                position: absolute;
                top: 50%;
                width: 8px;
                height: 8px;
                border-left: 2px solid #D9D9D9;
                border-bottom: 2px solid #D9D9D9;
            }
            .SwiperCardB-in-l {
                border-right: 1px solid #D9D9D9;
                .SwiperCardB-icon {
                    right: 24px;
                    transform: rotate(45deg);
                }
            }
            .SwiperCardB-in-r {
                border-left: 1px solid #D9D9D9;
                .SwiperCardB-icon {
                    left: 24px;
                    transform: rotate(-135deg);
                }
            }
        }
        .swiper-box {
            width: calc(100vw - 244px);
            .swiper-wrapper{
                justify-content: center;
            }
            .swiper-button-prev, .swiper-button-next {
                display: none;
            }
            &.over{
                width: 100%;
                padding: 0 122px;
                .swiper-button-prev, .swiper-button-next {
                    display: block;
                }
                .swiper-wrapper{
                    justify-content: flex-start;
                }
            }
            .swiper-pagination {
                display: none;
            }
            .swiper-button-prev, .swiper-button-next {
                width: 122px;
                top: 0;
                margin-top: 0;
                border-radius: 0;
                background-color: #fff;
                &.swiper-button-disabled {
                    display: none;
                }
                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-left: 3px solid #49494B;
                    border-bottom: 3px solid #49494B;
                }
            }
            .swiper-button-prev {
                left: -0;
                height: 100%;
                border-right: 1px solid #D2D2D7;
                html[site='sa'] & {
                    right: auto;
                }
                &::after {
                    left: auto;
                    right: 18px;
                    transform: translateY(-50%) rotate(45deg);
                }
            }
            .swiper-button-next {
                right: -0;
                height: 100%;
                border-left: 1px solid #D2D2D7;
                &::after {
                    content: '';
                    left: 18px;
                    transform: translateY(-50%) rotate(-135deg);
                }
            }
        }
        .swiper-item {
            position: relative;
            width: 262px;
            height: auto;
            padding-bottom: 29px;
            &:last-child{
                margin-right: 0 !important;
            }
            &:hover {
                .banner-img {
                    background-color: rgba($color: #f3f4f7, $alpha: 0.5);
                }
            }
        }
        .banner-img {
            width: 100%;
            height: 186px;
            background-color: #F3F4F7;
            border-radius: 14px;
            .lowcode-img {
                width: 186px;
                margin: 0 auto;
            }
        }
        .banner-ms-box {
            text-align: center;
            .banner-title {
                margin-top: 16px;
                font-family: var(--ff-woff2-B);
                font-size: 18px;
                line-height: 1.2;
                color: #000;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .banner-desc {
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.2;
                color: #626262;
                margin-top: 6px;
                display: -webkit-box;
                max-width: 100%;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre-line;
            }
            .banner-btns {
                position: absolute;
                bottom: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.2;
                color: #3F68E0;
                .editor-link {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 7.5px;
                    color: inherit;
                    gap: 4px;
                }
            }
        }
        .btn-icon-b {
            display: flex;
            width: 12px;
            height: 12px;
            background-repeat: no-repeat;
            background-size: 12px 12px;
            background-position: center right;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNS4xNTU3NyAyLjIyNTM0TDkuMTk3MTQgNi4yNjY3MUw1LjE1NTc3IDEwLjMwODEiIHN0cm9rZT0iIzNGNjhFMCIgc3Ryb2tlLXdpZHRoPSIxLjA2MDg2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+);
            html[site='sa'] & {
                transform: rotate(180deg);
            }
            &:hover, &:active, &:focus{
                text-decoration: underline;
            }
        }

        @include devices(desktop-max) {
            .SwiperCardB-title {
                font-size: 34px;
                width: 792px;
                margin-bottom: 50px;
            }
            .swiper-box{
                width: 1400px;
                &.over{
                    width: 100%;
                    padding: 0 calc(50vw - 700px);
                    .swiper-button-next{
                        width: calc(50vw - 700px);
                        &::after{
                            left: 24px;
                        }
                    }
                    .swiper-button-prev{
                        width: calc(50vw - 700px);
                        &::after{
                            right: 24px;
                        }
                    }
                }
                .swiper-item{
                    border-radius: 16px;
                    width: 282px;
                    padding-bottom: 33px;
                    .banner-img {
                        height: 200px;
                        .lowcode-img {
                            width: 200px;
                        }
                    }
                    .banner-ms-box {
                        .banner-title {
                            font-size: 20px;
                            margin-top: 20px;
                        }
                        .banner-desc {
                            font-size: 16px;
                            margin-top: 8px;
                        }
                        .banner-btns {
                            margin: 0 8px;
                            .editor-link {
                                background-size: 13px 13px;
                            }
                        }
                    }
                }
            }
        }

        @include devices(pad) {
            position: relative;
            margin: 0 auto;
            .SwiperCardB-title{
                width: calc(100vw - 268px);
                font-size: 22px;
                margin-bottom: 30px;
            }
            .btn-icon-b {
                display: none !important;
            }
            .swiper-box{
                width: 100%;
                padding: 0 48px 32px;
                &.over{
                    padding: 0 48px 32px;
                }
                .swiper-button-prev, .swiper-button-next {
                    display: none;
                }
                .swiper-pagination{
                    display: flex;
                    bottom: 0;
                }
                .swiper-wrapper{
                    justify-content: flex-start;
                    .swiper-item{
                        padding: 0 32px 89px;
                        width: 327px;
                        background: #F3F4F7;
                        border-radius: 10px;
                        overflow: hidden;
                        .banner-img{
                            width: 160px;
                            height: 160px;
                            margin: 0 auto;
                        }
                        .lowcode-img {
                            width: 100%;
                        }
                        .banner-title{
                            margin-top: 0;
                            font-size: 16px;
                        }
                        .banner-desc{
                            margin-top: 6px;
                            font-size: 14px;
                            white-space: pre-line;
                        }
                        .banner-btns{
                            bottom: 32px;
                            width: max-content;
                            left: 50%;
                            transform: translateX(-50%);
                            .btn-icon{
                                min-width: 102px;
                                padding: 8px;
                                border-radius: 4px;
                                border: 1px solid #000;
                                margin: 0 8px;
                                background-image: none;
                                &.SwiperCardB-link-buy{
                                    background-color: #000;
                                    color: #fff;
                                }
                                &.SwiperCardB-link-more{
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include devices(h5) {
            .SwiperCardB-title{
                width: calc(100vw - 48px);
            }
            .swiper-box{
                width: 100%;
                padding: 0 24px 32px;
                &.over{
                    padding: 0 24px 32px;
                }
                .swiper-wrapper{
                    .swiper-item{
                        border-radius: 8px;
                    }
                }
            }
        }
    }
    &:global(.temp-black) {
        :global {
            .swiper-box .swiper-button-next {
                background-color: #000;
            }
        }
    }
    &:global(.temp-gray) {
        :global {
            .swiper-box .swiper-button-next {
                background-color: #F5F5F5;
            }
        }
    }
}