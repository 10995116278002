
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.ef-activity-score {
    position: relative;
    display: flex;
    width: 162px;
    height: 31px;
    font-family: var(--ff-woff2-B);
    font-size: 14px;
    .ef-activity-score-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('https://websiteoss.ecoflow.com/upload/image/1722419360825/activity-score.png');
        background-size: 100% 100%;
    }
    .ef-activity-score-l {
        white-space: nowrap;
        padding-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #745629;
        z-index: 10;
    }
    .ef-activity-score-r {
        padding-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 28px 28px 0;
        color: #000;
    }
    @include devices(desktop) {
    }
    @include devices(pad) {
    }
    @include devices(h5) {
    }
}
