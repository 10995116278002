
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

// 通用样式
.ef-activity-card-a {
    position: relative;
    border-radius: 4px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    .ef-activity-card-a-img {
        position: relative;
        width: 100%;
        height: 220px;
        .lowcode-img {
            margin: 0 auto;
        }
        .ef-img-item {
            background-size: contain;
        }
    }
    .ef-activity-card-a-msg {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .ef-activity-card-a-eco-credits {
        display: inline-flex;
        width: max-content;
        margin: 24px 24px 0;
        padding: 4px 8px;
        font-family: var(--ff-woff2-B);
        font-size: 12px;
        line-height: 16px;
        color: #FF7715;
        border: 1px solid #FF7715;
        border-radius: 14px;
    }
    

    
    // 位置-文案居中
    &.ef-activity-card-a-position-textC {
        .ef-activity-card-a-text {
            text-align: center;
            align-items: center;
        }
        .ef-activity-card-a-footer-price-a, .ef-activity-card-a-footer-price-b {
            text-align: center;
            justify-content: center;
        }
    }
    
    @include devices(desktop) {
        
    }
    @include devices(pad) {
        .ef-activity-card-a-img {
            height: 150px;
        }
    }
    @include devices(h5) {
        .ef-activity-card-a-eco-credits {
            margin: 12px 12px 0;
        }
    }
}
// 通用样式-中间标题文案
.ef-activity-card-a {
    .ef-activity-card-a-text {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
    }
    .ef-activity-card-a-title {
        font-family: var(--ff-woff2-B);
        font-size: 24px;
        line-height: 1.2;
        color: #000;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .ef-activity-card-a-desc {
        font-family: var(--ff-woff2-M);
        font-size: 16px;
        line-height: 1.2;
        color: #707070;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-top: 8px;
    }
    .ef-activity-card-a-subTitle {
        font-family: var(--ff-woff2-B);
        font-size: 16px;
        line-height: 1.2;
        color: #000;
        margin-top: 8px;
    }
    @include devices(pad) {
        // 中间标题文案
        .ef-activity-card-a-text {
            padding: 12px 20px 0;
        }
        .ef-activity-card-a-title {
            font-size: 16px;
        }
        .ef-activity-card-a-desc {
            font-size: 12px;
        }
        .ef-activity-card-a-subTitle {
            font-size: 14px;
        }
    }
    @include devices(h5) {}
}

// 通用样式-能挣多少优惠
.ef-activity-card-a-discount {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // OFF
    .ef-activity-card-a-off {
        position: relative;
        height: 34px;
        padding: 0 16px;
        border-radius: 0px 4px 4px 0px;
        background: linear-gradient(273deg, #FF4D15 -0.67%, #EC671C 30.36%, #FF975B 94.38%, #FFAB7A 107.64%); 
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
    .ef-activity-card-a-off-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .ef-activity-card-a-off-text {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-end;
    }
    .ef-activity-card-a-off-text1 {
        font-family: var(--ff-woff2-B);
        font-size: 20px;
    }
    .ef-activity-card-a-off-text2 {
        font-family: var(--ff-woff2-B);
        font-size: 14px;
    }
    // score
    .ef-activity-card-a-score {
        margin-top: 2px;
    }
}

// 通用样式-pcs
.ef-activity-card-a {
    .ef-activity-card-a-pcs {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }
    .ef-activity-card-a-pcs-line {
        width: 1px;
        height: 49px;
        background-color: #DDD;
    }
    .ef-activity-card-a-pcs-item-line1 {
        font-family: var(--ff-woff2-B);
        font-size: 20px;
        line-height: 1.2;
        color: #000;
    }
    .ef-activity-card-a-pcs-item-line2 {
        font-family: var(--ff-woff2-B);
        font-size: 14px;
        line-height: 1.2;
        color: #707070;
        margin-top: 8px;
    }
    @include devices(h5) {
        .ef-activity-card-a-pcs-item-line1 {
            font-size: 12px;
        }
        .ef-activity-card-a-pcs-item-line2 {
            font-size: 12px;
        }
    }
}

// 通用样式--footer部分
.ef-activity-card-a-footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 0 24px;
    // 底部-价格1
    .ef-activity-card-a-footer-price-arrow {
        display: flex;
        align-items: center;
        gap: 4px 8px;
        flex-wrap: wrap;
        font-family: var(--ff-woff2-B);
        color: #FF7715;
    }
    .ef-activity-card-a-footer-price-arrow-ms1 {
        font-size: 28px;
        line-height: 1.1;
    }
    .ef-activity-card-a-footer-price-arrow-ms2 {
        position: relative;
        display: flex;
        
        font-size: 14px;
        line-height: 1.1;
        height: 27px;
        padding-left: 14px;
        overflow: hidden;
        .ef-activity-card-a-footer-price-arrow-ms2-in {
            display: flex;
            align-items: center;
            border: 1px solid #FF7715;
            border-left: none;
            padding-right: 9px;;
            white-space: nowrap;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 6px;
            width: 27px;
            height: 27px;
            border-left: 1px solid #FF7715;
            border-top: 1px solid #FF7715;
            transform: rotate(-45deg);
        }
    }
    // 底部-价格2
    .ef-activity-card-a-footer-price-b {
        font-family: var(--ff-woff2-B);
        color: #707070;
        font-size: 16px;
        margin-top: 8px;
        display: inline-flex;
        gap: 8px;
    }
    .ef-activity-card-a-footer-price-b-ms2 {
        text-decoration: line-through;
    }
    .ef-activity-card-a-footer-links {
        display: flex;
        gap: 16px;
        margin-top: 20px;
    }
    .ef-activity-card-a-calculator {
        flex: 1;
        .ef-bundles-calculator {
            width: 100%;
        }
    }
    .ef-activity-card-a-link {
        flex: 1;
        padding: 11px 0;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: var(--ff-woff2-B);
        font-size: 16px;
        line-height: 1.2;
        cursor: pointer;
        border: 1px solid #000;
    }
    .ef-activity-card-a-link-more {
        color: #000;
    }
    .ef-activity-card-a-link-buy {
        color: #fff;
        background-color: #000;
    }
    .ef-activity-card-a-link-disabled {
        cursor: default;
        background-color: #999;
        border-color: #999;
    }

    .ef-activity-card-a-footer-btns-cart {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 16px;
    }
    .ef-bundles-calculator .ef-bundles-calculator-num {
        min-width: 32px;
    }
    @include devices(pad) {
        // 底部
        padding: 12px 20px 0;
        // 底部-价格1
        .ef-activity-card-a-footer-price-arrow {
            gap: 2px;
        }
        .ef-activity-card-a-footer-price-arrow-ms1 {
            font-size: 20px;
        }
        .ef-activity-card-a-footer-price-arrow-ms2 {
            font-size: 12px;
        }
        // 底部-价格2
        .ef-activity-card-a-footer-price-b {
            font-size: 12px;
            margin-top: 8px;
            gap: 4px;
        }
        // 底部-按钮
        .ef-activity-card-a-footer-btns {
            flex-direction: column;
            gap: 9px;
        }
        .ef-activity-card-a-link {
            font-size: 14px;
        }
    }
    @include devices(h5) {
        padding-top: 12px;
        .ef-activity-card-a-footer-links {
            flex-direction: column;
            gap: 8px;
        }
        .ef-activity-card-a-footer-btns-cart {
            flex-direction: column;
            gap: 8px;
            margin-top: 8px;
            .ef-bundles-calculator {
                width: 100%;
            }
        }
        .ef-activity-card-a-footer-price-arrow-ms1 {
            font-size: 16px;
        }
        .ef-activity-card-a-footer-price-arrow-ms2 {
            font-size: 12px;
            height: 17px;
            padding-left: 9px;
            &::before {
                left: 4px;
                width: 17px;
                height: 17px;
            }
        }
        .ef-activity-card-a-footer {
            padding: 0 8px;
        }
        .ef-activity-card-a-footer-price-b {
            display: inline;
        }
        .ef-bundles-calculator {
            height: 36px;
            .ef-bundles-calculator-red, .ef-bundles-calculator-add {
                width: 36px;
            }
        }
    }
}

// 单例样式-图片左右布局
.ef-activity-card-a-type-imgLR {
    flex-direction: row;
    .ef-activity-card-a-img {
        flex: 1;
        height: 380px;
    }
    .ef-activity-card-a-msg {
        flex: 1;
        padding-top: 72px;
    }
    .ef-activity-card-a-text, .ef-activity-card-a-footer {
        padding: 0 80px 0 50px;
    }
    .ef-activity-card-a-discount {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        gap: 12px;
        flex-direction: row-reverse;
        justify-content: flex-start;
        .ef-activity-card-a-off {
            width: 100px;
            height: 48px;
            
            padding-bottom: 8px;
            background: url('https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1719565287424/icon2.png') center no-repeat;
            background-size: 100% 100%;
        }
    }
    .ef-activity-card-a-footer {
        margin-top: 20px;
    }
    // 图片在右
    &.ef-activity-card-a-position-imgR {
        flex-direction: row-reverse;
        .ef-activity-card-a-discount {
            flex-direction: row;
        }
    }
    @include devices(pad) {
        flex-direction: column !important;
        .ef-activity-card-a-img {
            flex: none;
            width: 100%;
            height: 200px;
        }
        .ef-activity-card-a-msg {
            padding-top: 0 !important;
        }
        .ef-activity-card-a-discount {
            position: static;
            flex-direction: row;
            margin: 12px 0 0 20px;
            .ef-activity-card-a-off {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .ef-activity-card-a-text {
            padding: 12px 20px 0;
        }
        .ef-activity-card-a-footer {
            padding: 0 20px 0;
            margin-top: 12px;
        }
    }
    @include devices(h5) {
        .ef-activity-card-a-footer-links {
            flex-direction: row;
            gap: 16px;
        }
    }
}

// 单例样式-一行2个
.ef-activity-card-a-type-column2 {
    .ef-activity-card-a-discount {
        position: static;
        flex-direction: row;
        margin: 12px 12px 0;
        .ef-activity-card-a-off {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

// 单例样式-一行3个
.ef-activity-card-a-type-column3 {
    .ef-activity-card-a-discount {
        position: static;
        flex-direction: row;
        margin: 12px 12px 0;
        .ef-activity-card-a-off {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

// 单例样式-一行4个
.ef-activity-card-a-type-column4 {
    .ef-activity-card-a-discount {
        position: static;
        flex-direction: row;
        margin: 12px 12px 0;
        .ef-activity-card-a-off {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    // 价格
    .ef-activity-card-a-footer-price-d {
        display: flex;
        gap: 4px;
        align-items: center;
        align-items: center;
        font-family: var(--ff-woff2-B);
        color: #000;
    }
    .ef-activity-card-a-footer-price-d-ms1 {
        color: #FF7715;
        font-size: 28px;
        line-height: 1.1;
    }
    .ef-activity-card-a-footer-price-d-ms2 {
        color: #707070;
        font-size: 14px;
        line-height: 1.2;
        text-decoration: line-through;
    }
}

// 单例样式-一行3个(购物车)
.ef-activity-card-a-type-addCart3 {
    .ef-activity-card-a-discount {
        position: static;
        flex-direction: row;
        margin: 12px 12px 0;
        .ef-activity-card-a-off {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .ef-activity-card-a-footer-price-c-ms3 {
        color: #707070;
    }
    .ef-activity-card-a-footer-price-c-ms3-line {
        text-decoration: line-through;
    }
    @include devices(h5) {
        .ef-activity-card-a-footer-price-c {
            flex-wrap: wrap;
            .ef-activity-card-a-footer-price-arrow-ms2-in {
                padding-right: 2px;
            }
        }
        
    }
}