@import 'lowcode/common/css/devices.scss';

.swiper {
    .swiper-button-prev, .swiper-button-next {
        position: absolute;
        top: 50%;
        z-index: 3;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: -25px;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, .15);
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .15);
            border: 1px solid #fff;
            border-radius: 50%;
            transition: opacity 0.25s;
            box-sizing: border-box;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            box-sizing: border-box;
        }

        &:hover, &:active, &:focus {
            &::before {
                border-color: rgba(255, 255, 255, .35);
                background-color: rgba(0, 0, 0, .35);
            }
        }
    }
    .swiper-button-prev {
        left: 24px;
        right: auto;
        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
            margin-left: 2px;
        }
    }
    .swiper-button-next {
        right: 24px;
        &::after {
            transform: translate(-50%, -50%) rotate(-135deg);
            margin-left: -1px;
        }
    }
    @include devices(desktop-max) {
        .swiper-button-prev, .swiper-button-next {
            width: 60px;
            height: 60px;
            &::after {
                width: 12px;
                height: 12px;
                border-width: 2.45px;
            }
        }
    }
    @include devices(pad) {
        .swiper-button-prev, .swiper-button-next {
            width: 40px;
            height: 40px;
            &::after {
                border-width: 1.6px;
                width: 8px;
                height: 8px;
            }
        }
        .swiper-button-prev {
            left: 28px;
        }
        .swiper-button-next {
            right: 28px;
        }
    }
    @include devices(h5) {
        .swiper-button-prev, .swiper-button-next {
           display: none;
        }
    }
}